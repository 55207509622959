import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { CommandBarButton, IconButton, Dialog, DialogType, Stack } from "@fluentui/react";
import { SquareRegular, ShieldLockRegular, ErrorCircleRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';
import { isEmpty } from "lodash";
import DOMPurify from 'dompurify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from "./Chat.module.css";
import Contoso from "../../assets/hitachi-logo.svg";
import AkiraPictogram from "../../assets/akira_pictogram.svg";
import { XSSAllowTags } from "../../constants/xssAllowTags";

import {
  ChatMessage,
  ConversationRequest,
  conversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  getUserInfo,
  Conversation,
  historyGenerate,
  historyUpdate,
  historyClear,
  ChatHistoryLoadingState,
  CosmosDBStatus,
  ErrorMessage
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ChatHistoryPanel } from "../../components/ChatHistory/ChatHistoryPanel";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";

const enum messageStatus {
  NotRunning = "Not Running",
  Processing = "Processing",
  Done = "Done"
}

const Chat = () => {
  const appStateContext = useContext(AppStateContext)
  const ui = appStateContext?.state.frontendSettings?.ui;
  const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [activeCitation, setActiveCitation] = useState<Citation>();
  const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
  const abortFuncs = useRef([] as AbortController[]);
  const [showAuthMessage, setShowAuthMessage] = useState<boolean | undefined>();
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
  const [clearingChat, setClearingChat] = useState<boolean>(false);
  const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
  const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
  const [hover, setHover] = useState(false);
  const [onLoad, setOnLoad] = useState(true);

  const [onHoverChat, setHoverNewChat] = useState(false);
  const errorDialogContentProps = {
    type: DialogType.close,
    title: errorMsg?.title,
    closeButtonAriaLabel: 'Close',
    subText: errorMsg?.subtitle,
  };

  const modalProps = {
    titleAriaId: 'labelId',
    subtitleAriaId: 'subTextId',
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  }

  const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]
  const NO_CONTENT_ERROR = "No content in messages object."

  useEffect(() => {
    if (appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.Working
      && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured
      && appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail
      && hideErrorDialog) {
      let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
      setErrorMsg({
        title: "Chat history is not enabled",
        subtitle: subtitle
      })
      toggleErrorDialog();
    }
  }, [appStateContext?.state.isCosmosDBAvailable]);

  const handleErrorDialogClose = () => {
    toggleErrorDialog()
    setTimeout(() => {
      setErrorMsg(null)
    }, 500);
  }

  useEffect(() => {
    setIsLoading(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading)
  }, [appStateContext?.state.chatHistoryLoadingState])

  const getUserInfoList = async () => {
    if (!AUTH_ENABLED) {
      setShowAuthMessage(false);
      return;
    }
    const userInfoList = await getUserInfo();
    if (userInfoList.length === 0 && window.location.hostname !== "0.0.0.0") {
      setShowAuthMessage(false);
    }
    else {
      setShowAuthMessage(false);
    }
  }

  let assistantMessage = {} as ChatMessage
  let toolMessage = {} as ChatMessage
  let assistantContent = ""

  const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
    if (resultMessage.role === ASSISTANT) {
      assistantContent += resultMessage.content
      assistantMessage = resultMessage
      assistantMessage.content = assistantContent

      if (resultMessage.context) {
        toolMessage = {
          id: uuid(),
          role: TOOL,
          content: resultMessage.context,
          date: new Date().toISOString(),
        }
      }
    }

    if (resultMessage.role === TOOL) toolMessage = resultMessage

    if (!conversationId) {
      isEmpty(toolMessage) ?
        setMessages([...messages, userMessage, assistantMessage]) :
        setMessages([...messages, userMessage, toolMessage, assistantMessage]);
    } else {
      isEmpty(toolMessage) ?
        setMessages([...messages, assistantMessage]) :
        setMessages([...messages, toolMessage, assistantMessage]);
    }
    ScrollToBottom();
  }

  const makeApiRequestWithoutCosmosDB = async (question: string, conversationId?: string) => {
    setIsLoading(true);
    setOnLoad(false);
    ScrollToBottom();
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: "user",
      content: question,
      date: new Date().toISOString(),
    };

    let conversation: Conversation | null | undefined;
    if (!conversationId) {
      conversation = {
        id: conversationId ?? uuid(),
        title: question,
        messages: [userMessage],
        date: new Date().toISOString(),
      }
    } else {
      conversation = appStateContext?.state?.currentChat
      if (!conversation) {
        console.error("Conversation not found.");
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        return;
      } else {
        conversation.messages.push(userMessage);
      }
    }

    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
    setMessages(conversation.messages)
    ScrollToBottom();
    const request: ConversationRequest = {
      messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
    };

    let result = {} as ChatResponse;
    try {
      const response = await conversationApi(request, abortController.signal);
      if (response?.body) {
        const reader = response.body.getReader();

        let runningText = "";
        while (true) {
          setProcessMessages(messageStatus.Processing)
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach((obj) => {
            try {
              if (obj !== "" && obj !== "{}") {
                runningText += obj;
                result = JSON.parse(runningText);
                if (result.choices?.length > 0) {
                  result.choices[0].messages.forEach((msg) => {
                    msg.id = result.id;
                    msg.date = new Date().toISOString();
                  })
                  if (result.choices[0].messages?.some(m => m.role === ASSISTANT)) {
                    setShowLoadingMessage(false);
                  }
                  result.choices[0].messages.forEach((resultObj) => {
                    processResultMessage(resultObj, userMessage, conversationId);
                    ScrollToBottom();
                  })
                }
                else if (result.error) {
                  throw Error(result.error);
                }
                runningText = "";
              }
            }
            catch (e) {
              if (!(e instanceof SyntaxError)) {
                console.error(e);
                throw e;
              } else {
                console.log("Incomplete message. Continuing...")
              }
            }
          });
        }
        conversation.messages.push(toolMessage, assistantMessage)
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages([...messages, toolMessage, assistantMessage]);
        ScrollToBottom();
      }

    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
        if (result.error?.message) {
          errorMessage = result.error.message;
        }
        else if (typeof result.error === "string") {
          errorMessage = result.error;
        }

        errorMessage = parseErrorMessage(errorMessage);

        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString()
        }
        conversation.messages.push(errorChatMsg);
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage])
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
      setProcessMessages(messageStatus.Done)
    }
    ScrollToBottom();
    return abortController.abort();
  };

  const makeApiRequestWithCosmosDB = async (question: string, conversationId?: string) => {
    setIsLoading(true);
    setOnLoad(false);
    ScrollToBottom();
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: "user",
      content: question,
      date: new Date().toISOString(),
    };

    //api call params set here (generate)
    let request: ConversationRequest;
    let conversation;
    if (conversationId) {
      conversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
      if (!conversation) {
        console.error("Conversation not found.");
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        return;
      } else {
        conversation.messages.push(userMessage);
        request = {
          messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)]
        };
      }
    } else {
      request = {
        messages: [userMessage].filter((answer) => answer.role !== ERROR)
      };
      setMessages(request.messages)
      ScrollToBottom();
    }
    let result = {} as ChatResponse;
    var errorResponseMessage = "Please try again. If the problem persists, please contact the site administrator.";
    try {
      const response = conversationId ? await historyGenerate(request, abortController.signal, conversationId) : await historyGenerate(request, abortController.signal);
      if (!response?.ok) {
        const responseJson = await response.json();
        errorResponseMessage = responseJson.error === undefined ? errorResponseMessage : parseErrorMessage(responseJson.error);
        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: `There was an error generating a response. Chat history can't be saved at this time. ${errorResponseMessage}`,
          date: new Date().toISOString()
        }
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
          if (!resultConversation) {
            console.error("Conversation not found.");
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            return;
          }
          resultConversation.messages.push(errorChatMsg);
        } else {
          setMessages([...messages, userMessage, errorChatMsg])
          ScrollToBottom();
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
          return;
        }
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
        setMessages([...resultConversation.messages]);
        ScrollToBottom();
        return;
      }
      if (response?.body) {
        const reader = response.body.getReader();

        let runningText = "";
        while (true) {
          setProcessMessages(messageStatus.Processing)
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach((obj) => {
            try {
              if (obj !== "" && obj !== "{}") {
                runningText += obj;
                result = JSON.parse(runningText);
                if (!result.choices?.[0]?.messages?.[0].content) {
                  errorResponseMessage = NO_CONTENT_ERROR;
                  throw Error();
                }
                if (result.choices?.length > 0) {
                  result.choices[0].messages.forEach((msg) => {
                    msg.id = result.id;
                    msg.date = new Date().toISOString();
                  })
                  if (result.choices[0].messages?.some(m => m.role === ASSISTANT)) {
                    setShowLoadingMessage(false);
                  }
                  result.choices[0].messages.forEach((resultObj) => {
                    processResultMessage(resultObj, userMessage, conversationId);
                    ScrollToBottom();
                  })
                }
                runningText = "";
              }
              else if (result.error) {
                throw Error(result.error);
              }
            }
            catch (e) {
              if (!(e instanceof SyntaxError)) {
                console.error(e);
                throw e;
              } else {
                console.log("Incomplete message. Continuing...")
              }
            }
          });
        }

        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
          if (!resultConversation) {
            console.error("Conversation not found.");
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            return;
          }
          isEmpty(toolMessage) ?
            resultConversation.messages.push(assistantMessage) :
            resultConversation.messages.push(toolMessage, assistantMessage)
        } else {
          resultConversation = {
            id: result.history_metadata.conversation_id,
            title: result.history_metadata.title,
            messages: [userMessage],
            date: result.history_metadata.date
          }
          isEmpty(toolMessage) ?
            resultConversation.messages.push(assistantMessage) :
            resultConversation.messages.push(toolMessage, assistantMessage)
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
          return;
        }
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
        isEmpty(toolMessage) ?
          setMessages([...messages, assistantMessage]) :
          setMessages([...messages, toolMessage, assistantMessage]);
          ScrollToBottom();
      }

    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage = `An error occurred. ${errorResponseMessage}`;
        if (result.error?.message) {
          errorMessage = result.error.message;
        }
        else if (typeof result.error === "string") {
          errorMessage = result.error;
        }

        errorMessage = parseErrorMessage(errorMessage);

        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString()
        }
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
          if (!resultConversation) {
            console.error("Conversation not found.");
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            return;
          }
          resultConversation.messages.push(errorChatMsg);
        } else {
          if (!result.history_metadata) {
            console.error("Error retrieving data.", result);
            let errorChatMsg: ChatMessage = {
              id: uuid(),
              role: ERROR,
              content: errorMessage,
              date: new Date().toISOString()
            }
            setMessages([...messages, userMessage, errorChatMsg])
            ScrollToBottom();
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            return;
          }
          resultConversation = {
            id: result.history_metadata.conversation_id,
            title: result.history_metadata.title,
            messages: [userMessage],
            date: result.history_metadata.date
          }
          resultConversation.messages.push(errorChatMsg);
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
          return;
        }
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
        setMessages([...messages, errorChatMsg]);
        ScrollToBottom();
      } else {
        setMessages([...messages, userMessage])
        ScrollToBottom();
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
      setProcessMessages(messageStatus.Done)
    }
    return abortController.abort();

  }

  const clearChat = async () => {
    setClearingChat(true)
    if (appStateContext?.state.currentChat?.id && appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
      let response = await historyClear(appStateContext?.state.currentChat.id)
      if (!response.ok) {
        setErrorMsg({
          title: "Error clearing current chat",
          subtitle: "Please try again. If the problem persists, please contact the site administrator.",
        })
        toggleErrorDialog();
      } else {
        appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
        appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state.currentChat });
        setActiveCitation(undefined);
        setIsCitationPanelOpen(false);
        setMessages([])
      }
    }
    setClearingChat(false)
  };

  const onHover = () => {

    if(!disabledButton())
    {
    setHover(true);

    const element = document.getElementById('tooltipClear');

    if (element) {
      element.style.position= 'absolute';    
      element.style.background= '#e8e9e6';
      element.style.padding= '8px';
      element.style.left= '50px';
    }
  }
  };

  const onLeave = () => {
    setHover(false);
    const element = document.getElementById('tooltipClear');

    if (element) {      
      element.style.background= 'transparent';     
    }
  };

  const onHoverNewChat = () => {

    if(!disabledButton())
    {
    setHoverNewChat(true);

    const element = document.getElementById('tooltipchat');

    if (element) {
      element.style.position= 'absolute';    
      element.style.background= '#e8e9e6';
      element.style.padding= '8px';
      element.style.left= '50px';
    }
  }
  };

  const onLeaveNewChat = () => {
    setHoverNewChat(false);
    const element = document.getElementById('tooltipchat');

    if (element) {      
      element.style.background= 'transparent';     
    }
  };

  const parseErrorMessage = (errorMessage: string) => {
    let errorCodeMessage = errorMessage.substring(0, errorMessage.indexOf("-") + 1);
    const innerErrorCue = "{\\'error\\': {\\'message\\': ";
    if (errorMessage.includes(innerErrorCue)) {
      try {
        let innerErrorString = errorMessage.substring(errorMessage.indexOf(innerErrorCue));
        if (innerErrorString.endsWith("'}}")) {
          innerErrorString = innerErrorString.substring(0, innerErrorString.length - 3);
        }
        innerErrorString = innerErrorString.replaceAll("\\'", "'")
        let newErrorMessage = errorCodeMessage + " " + innerErrorString;
        errorMessage = newErrorMessage;

      } catch (e) {
        console.error("Error parsing inner error message: ", e);
      }
    }
    return errorMessage;
  };

  const newChat = () => {
    setProcessMessages(messageStatus.Processing)
    setMessages([])
    setIsCitationPanelOpen(false);
    setActiveCitation(undefined);
    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
    setProcessMessages(messageStatus.Done)
  };

  const stopGenerating = () => {
    abortFuncs.current.forEach(a => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  }

  useEffect(() => {
    if (appStateContext?.state.currentChat) {
      setMessages(appStateContext.state.currentChat.messages)
      ScrollToBottom();
    } else {
      setMessages([])
    }
  }, [appStateContext?.state.currentChat]);

  useLayoutEffect(() => {
    const saveToDB = async (messages: ChatMessage[], id: string) => {
      const response = await historyUpdate(messages, id)
      return response
    }

    if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
      if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
        if (!appStateContext?.state.currentChat?.messages) {
          console.error("Failure fetching current chat state.")
          return
        }
        const noContentError = appStateContext.state.currentChat.messages.find(m => m.role === ERROR)

        if (!noContentError?.content.includes(NO_CONTENT_ERROR)) {
          saveToDB(appStateContext.state.currentChat.messages, appStateContext.state.currentChat.id)
            .then((res) => {
              if (!res.ok) {
                let errorMessage = "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
                let errorChatMsg: ChatMessage = {
                  id: uuid(),
                  role: ERROR,
                  content: errorMessage,
                  date: new Date().toISOString()
                }
                if (!appStateContext?.state.currentChat?.messages) {
                  let err: Error = {
                    ...new Error,
                    message: "Failure fetching current chat state."
                  }
                  throw err
                }
                setMessages([...appStateContext?.state.currentChat?.messages, errorChatMsg])
                ScrollToBottom();
              }
              return res as Response
            })
            .catch((err) => {
              console.error("Error: ", err)
              let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
              }
              return errRes;
            })
        }
      } else {
      }
      appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
      setMessages(appStateContext.state.currentChat.messages)
      ScrollToBottom();
      setProcessMessages(messageStatus.NotRunning)
    }
  }, [processMessages]);

  useEffect(() => {
    if (AUTH_ENABLED !== undefined) getUserInfoList();
  }, [AUTH_ENABLED]);

  const ScrollToBottom = () => {
    // if (showLoadingMessage)
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
}

  useLayoutEffect(() => {
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
  }, [showLoadingMessage, processMessages]);

  const onShowCitation = (citation: Citation) => {
    setActiveCitation(citation);
    setIsCitationPanelOpen(true);
  };

  const onViewSource = (citation: Citation) => {
    if (citation.url && !citation.url.includes("blob.core")) {
      window.open(citation.url, "_blank");
    }
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message?.role && message?.role === "tool") {
      try {
        const toolMessage = JSON.parse(message.content) as ToolMessageContent;
        return toolMessage.citations;
      }
      catch {
        return [];
      }
    }
    return [];
  }

  const disabledButton = () => {
    return isLoading || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
  }

  return (
    <div className={styles.container} role="main">
      {showAuthMessage ? (
        <Stack className={styles.chatEmptyState}>
          <ShieldLockRegular className={styles.chatIcon} style={{ color: 'darkorange', height: "200px", width: "200px" }} />
          <h1 className={styles.chatEmptyStateTitle}>Authentication Not Configured</h1>
          <h2 className={styles.chatEmptyStateSubtitle}>
            This app does not have authentication configured. Please add an identity provider by finding your app in the <a href="https://portal.azure.com/" target="_blank">Azure Portal</a>
            and following <a href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization" target="_blank">these instructions</a>.
          </h2>
          <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>Authentication configuration takes a few minutes to apply. </strong></h2>
          <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>If you deployed in the last 10 minutes, please wait and reload the page after 10 minutes.</strong></h2>
        </Stack>
      ) : (
        <Stack horizontal className={styles.chatRoot}>
          <div className={styles.chatContainer}>          
            {!messages || messages.length < 1 ? (
              <Stack className={styles.chatEmptyState}>
                 <img
                                    src={AkiraPictogram}
                                    className={styles.chatIcon}
                                    aria-hidden="true"
                                />   
                <h1 className={styles.chatEmptyStateTitle}>{ui?.chat_title}</h1>
                <h2 className={styles.chatEmptyStateSubtitle} style={{textAlign:'justify'}}>
                  <div>Disclaimer</div>
                  <div>Akira is our AI solution to support Hitachi Energy employees with the latest GenAI technology.</div>
                  <div>You can ask questions regarding any topic and subject matter.</div>
                  <div>Please do not enter into the chat any data involving:</div>
                  <div style={{    width: "100%",paddingLeft: "3vw"}}>
                    <div>- Personal Data</div>
                    <div>- Health</div>
                    <div>- Financial</div>
                  </div>          
                </h2>
              </Stack>
            ) : (
              <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                {messages.map((answer, index) => (
                  <>
                    {answer.role === "user" ? (
                      <div className={styles.chatMessageUser} tabIndex={0}>
                        <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                      </div>
                    ) : (
                      answer.role === "assistant" ? <div><img className={styles.UserProfile} src={AkiraPictogram} alt="Avatar">
                        </img><div className={styles.chatMessageGpt}>
                        <Answer
                          answer={{
                            answer: answer.content,
                            citations: parseCitationFromMessage(messages[index - 1]),
                            message_id: answer.id,
                            feedback: answer.feedback
                          }}
                          onCitationClicked={c => onShowCitation(c)}
                        />
                      </div></div> : answer.role === ERROR ? <div className={styles.chatMessageError}>
                        <Stack horizontal className={styles.chatMessageErrorContent}>
                          <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                          <span>Error</span>
                        </Stack>
                        <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                      </div> : null
                    )}
                  </>
                ))}
                {showLoadingMessage && (
                  <>
                    <div className={styles.chatMessageGpt}>
                      <Answer
                        answer={{
                          answer: "Generating answer...",
                          citations: []
                        }}
                        onCitationClicked={() => null}
                      />
                    </div>
                  </>
                )}
                <div ref={chatMessageStreamEnd} />
              </div>
            )}

            <Stack horizontal className={styles.chatInput}>
              {isLoading && (
                <Stack
                  horizontal
                  className={styles.stopGeneratingContainer}
                  role="button"
                  aria-label="Stop generating"
                  tabIndex={0}
                  onClick={stopGenerating}
                  onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                >
                  <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true" />
                  <span className={styles.stopGeneratingText} aria-hidden="true">Stop generating</span>
                </Stack>
              )}
              <Stack>
                {appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && 
                  <div id="newChat" onMouseEnter={onHoverNewChat} 
                  onMouseLeave={onLeaveNewChat} >
                    <span id="tooltipchat" className={styles.tooltipNewChat}> {onHoverChat ? "Start a new chat" : ''}</span>
                    <CommandBarButton
                  role="button"
                  styles={{
                    icon: {
                      color: '#FFFFFF',
                    },
                    iconDisabled: {
                      color: "#BDBDBD !important"
                    },
                    root: {
                      color: '#FFFFFF',
                      background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #eb0023 33.63%, #eb0023 70.31%, #eb0023 100%)"
                    },
                    rootDisabled: {
                      background: "#F0F0F0"
                    }
                  }}
                  className={styles.newChatIcon}
                  iconProps={{ iconName: 'Add' }}
                  onClick={newChat}
                  disabled={disabledButton()}
                  aria-label="start a new chat button"
                /></div>}
                { <div id="clearchat" onMouseEnter={onHover} 
      onMouseLeave={onLeave} >
        <span id="tooltipClear" className={styles.tooltip}> {hover ? "Clear Chat" : ''}</span>
                <CommandBarButton
                  role="button"
                  styles={{
                    icon: {
                      color: '#FFFFFF',
                    },
                    iconDisabled: {
                      color: "#BDBDBD !important",
                    },
                    root: {
                      color: '#FFFFFF',
                      background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #eb0023 33.63%, #eb0023 70.31%, #eb0023 100%)",
                    },
                    rootDisabled: {
                      background: "#F0F0F0"
                    }
                  }}
                  className={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? styles.clearChatBroom : styles.clearChatBroomNoCosmos}
                  iconProps={{ iconName: 'Broom' }}
                  onClick={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? clearChat : newChat}
                  disabled={disabledButton()}
                  aria-label="clear chat button"> 
                  </CommandBarButton>
                  </div> }
                <Dialog
                  hidden={hideErrorDialog}
                  onDismiss={handleErrorDialogClose}
                  dialogContentProps={errorDialogContentProps}
                  modalProps={modalProps}
                >
                </Dialog>
              </Stack>
              <QuestionInput
                clearOnSend
                placeholder= {onLoad ? "Hello, I'm Akira, How can I help you today?" : "Type a new question..."}
                disabled={isLoading}
              
                onSend={(question, id) => {
                  appStateContext?.state.isCosmosDBAvailable?.cosmosDB ? makeApiRequestWithCosmosDB(question, id) : makeApiRequestWithoutCosmosDB(question, id)
                }}
                conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
              />
            </Stack>
          </div>
          {/* Citation Panel */}
          {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
            <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
              <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                <span aria-label="Citations" className={styles.citationPanelHeader}>Citations</span>
                <IconButton iconProps={{ iconName: 'Cancel' }} aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)} />
              </Stack>
              <h5 className={styles.citationPanelTitle} tabIndex={0} title={activeCitation.url && !activeCitation.url.includes("blob.core") ? activeCitation.url : activeCitation.title ?? ""} onClick={() => onViewSource(activeCitation)}>{activeCitation.title}</h5>
              <div tabIndex={0}>
                <ReactMarkdown
                  linkTarget="_blank"
                  className={styles.citationPanelContent}
                  children={DOMPurify.sanitize(activeCitation.content, { ALLOWED_TAGS: XSSAllowTags })}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </Stack.Item>
          )}
          {(appStateContext?.state.isChatHistoryOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ChatHistoryPanel />}
        </Stack>
      )}
    </div>
  );
};

export default Chat;