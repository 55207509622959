import { useContext, useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Button, Dialog, Stack, TextField } from '@fluentui/react'
import { CopyRegular } from '@fluentui/react-icons'
import Table from 'react-bootstrap/Table';
import { CosmosDBStatus } from '../../api'
import Contoso from '../../assets/hitachi-logo.svg'
import csLogo from '../../assets/Hitachi-energy-mark.svg'
import { HistoryButton, ShareButton } from '../../components/common/Button'
import { AppStateContext } from '../../state/AppProvider'

import styles from './Layout.module.css'
import Modal from 'react-bootstrap/Modal';
import Navbar from '../../components/Header/Navbar';

const Layout = () => {
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false)
  const [copyClicked, setCopyClicked] = useState<boolean>(false)
  const [copyText, setCopyText] = useState<string>('Copy URL')
  const [shareLabel, setShareLabel] = useState<string | undefined>('Share')
  const [hideHistoryLabel, setHideHistoryLabel] = useState<string>('Hide chat history')
  const [showHistoryLabel, setShowHistoryLabel] = useState<string>('Show chat history')
  const appStateContext = useContext(AppStateContext)
  const ui = appStateContext?.state.frontendSettings?.ui
  const [modalShow, setModalShow] = useState<boolean>(false);
  const handleShareClick = () => {
    setIsSharePanelOpen(true)
  }

  const OpenMailClient =() => {
    window.open('mailto:pl-pm_intelligent_assistant@hitachienergy.com')  
  }

  const OpenExtLink =() => {
    window.open('https://hitachipowergrids.sharepoint.com/:f:/r/sites/PM_Intelligent_Assistant/Shared%20Documents/Materials?csf=1&web=1&e=7qBxVK')  
  }

  const OpenDataSources =() => {
    window.open('https://hitachipowergrids.sharepoint.com/:x:/r/sites/PM_Intelligent_Assistant/Shared%20Documents/Data%20Sources.xlsx?d=wbd7dbf578cbb47b3a60a0a184af18c89&csf=1&web=1&e=dbSSym')  
  }

  const handleSharePanelDismiss = () => {
    setIsSharePanelOpen(false)
    setCopyClicked(false)
    setCopyText('Copy URL')
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href)
    setCopyClicked(true)
  }

  const handleHistoryClick = () => {
    appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
  }

  function MyVerticallyCenteredModal(props:any) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Data Sources
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Data Source Name</th>
          {/* <th>Links</th>           */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>OneView</td>
          {/* <td><a href='https://xecm.hitachienergy.com/otcs/llisapi.dll/open/oneview' target="_blank" rel="noopener noreferrer">Click Here</a></td> */}
         
        </tr>
        <tr>
          <td>2</td>
          <td>Publisher</td>
          {/* <td><a target="_blank" rel="noopener noreferrer" href='https://publisher.hitachienergy.com/documents?docType=All+Files&q=project+execution'>Click Here</a></td> */}
                </tr>
        <tr>
          <td>3</td>
          <td>ProMIS User Guide</td>
          {/* <td><a target="_blank" rel="noopener noreferrer" href='https://hitachipowergrids.sharepoint.com/sites/Global_FI_Risk-Management/ProMISUserGuide'>Click Here</a></td> */}
        </tr>
       
        <tr>
          <td>4</td>
          <td>TBMS SharePoint</td>
          {/* <td><a href='https://hitachipowergrids.sharepoint.com/:f:/r/teams/PGGIOperationsManagement/Shared%20Documents/TBMS?csf=1&web=1&e=7jTuz0' target="_blank" rel="noopener noreferrer">Click Here</a></td> */}
        </tr>
        <tr>
          <td>5</td>
          <td>EPIC SharePoint</td>
          {/* <td></td> */}
        </tr>
        <tr>
          <td>6</td>
          <td>Mission Control Center</td>
          {/* <td></td> */}
        </tr>
        <tr>
          <td>7</td>
          <td>Relex End Users Manuals</td>
          {/* <td></td> */}
        </tr>
      </tbody>
    </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  

  useEffect(() => {
    if (copyClicked) {
      setCopyText('Copied URL')
    }
  }, [copyClicked])

  useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setShareLabel(undefined)
        setHideHistoryLabel('Hide history')
        setShowHistoryLabel('Show history')
      } else {
        setShareLabel('Share')
        setHideHistoryLabel('Hide chat history')
        setShowHistoryLabel('Show chat history')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
 
    <div className={styles.layout}>
         <Navbar></Navbar>         
            <Outlet />
      <Dialog
        onDismiss={handleSharePanelDismiss}
        hidden={!isSharePanelOpen}
        styles={{
          main: [
            {
              selectors: {
                ['@media (min-width: 480px)']: {
                  maxWidth: '600px',
                  background: '#FFFFFF',
                  boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  maxHeight: '200px',
                  minHeight: '100px'
                }
              }
            }
          ]
        }}
        dialogContentProps={{
          title: 'Share the web app',
          showCloseButton: true
        }}>
        <Stack horizontal verticalAlign="center" style={{ gap: '8px' }}>
          <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
          <div
            className={styles.copyButtonContainer}
            role="button"
            tabIndex={0}
            aria-label="Copy"
            onClick={handleCopyClick}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? handleCopyClick() : null)}>
            <CopyRegular className={styles.copyButton} />
            <span className={styles.copyButtonText}>{copyText}</span>
          </div>
        </Stack>
      </Dialog>
          </div>
  )
}

export default Layout
