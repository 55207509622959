import { Link, Stack } from '@fluentui/react';
import { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AppStateContext } from '../../state/AppProvider';
import styles from './Navbar.module.css'
import Contoso from '../../assets/hitachi-logo.svg'
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
// import Button from 'react-bootstrap/Button';
import { Button, Dialog, TextField } from '@fluentui/react'
import csLogo from '../../assets/Hitachi-energy-mark.svg'
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import { CopyRegular } from '@fluentui/react-icons';
import { CosmosDBStatus } from '../../api';

const BasicExample= () => {
  const appStateContext = useContext(AppStateContext)
  const ui = appStateContext?.state.frontendSettings?.ui
  const [hideHistoryLabel, setHideHistoryLabel] = useState<string>('Hide chat history')
  const [showHistoryLabel, setShowHistoryLabel] = useState<string>('Show chat history')
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false)
  const [copyClicked, setCopyClicked] = useState<boolean>(false)
  const [copyText, setCopyText] = useState<string>('Copy URL')
  const [shareLabel, setShareLabel] = useState<string | undefined>('Share')

  const handleShareClick = () => {
    setIsSharePanelOpen(true)
  }

  const handleSharePanelDismiss = () => {
    setIsSharePanelOpen(false)
    setCopyClicked(false)
    setCopyText('Copy URL')
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href)
    setCopyClicked(true)
  }

  const handleHistoryClick = () => {
    appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
  }

  function MyVerticallyCenteredModal(props:any) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Data Sources
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Data Source Name</th>
          {/* <th>Links</th>           */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>OneView</td>
          {/* <td><a href='https://xecm.hitachienergy.com/otcs/llisapi.dll/open/oneview' target="_blank" rel="noopener noreferrer">Click Here</a></td> */}
         
        </tr>
        <tr>
          <td>2</td>
          <td>Publisher</td>
          {/* <td><a target="_blank" rel="noopener noreferrer" href='https://publisher.hitachienergy.com/documents?docType=All+Files&q=project+execution'>Click Here</a></td> */}
                </tr>
        <tr>
          <td>3</td>
          <td>ProMIS User Guide</td>
          {/* <td><a target="_blank" rel="noopener noreferrer" href='https://hitachipowergrids.sharepoint.com/sites/Global_FI_Risk-Management/ProMISUserGuide'>Click Here</a></td> */}
        </tr>
       
        <tr>
          <td>4</td>
          <td>TBMS SharePoint</td>
          {/* <td><a href='https://hitachipowergrids.sharepoint.com/:f:/r/teams/PGGIOperationsManagement/Shared%20Documents/TBMS?csf=1&web=1&e=7jTuz0' target="_blank" rel="noopener noreferrer">Click Here</a></td> */}
        </tr>
        <tr>
          <td>5</td>
          <td>EPIC SharePoint</td>
          {/* <td></td> */}
        </tr>
        <tr>
          <td>6</td>
          <td>Mission Control Center</td>
          {/* <td></td> */}
        </tr>
        <tr>
          <td>7</td>
          <td>Relex End Users Manuals</td>
          {/* <td></td> */}
        </tr>
        <tr>
          <td>8</td>
          <td>CCRP End Users Manuals</td>
          {/* <td></td> */}
        </tr>        
        <tr>
          <td>9</td>
          <td>Internal Acronyms list</td>
          {/* <td></td> */}
        </tr>  
        <tr>
          <td>10</td>
          <td>Communication Toolkit </td>
          {/* <td></td> */}
        </tr>  

      </tbody>
    </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} className={styles.feedbackBtn}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  

  useEffect(() => {
    if (copyClicked) {
      setCopyText('Copied URL')
    }
  }, [copyClicked])

  useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setShareLabel(undefined)
        setHideHistoryLabel('Hide history')
        setShowHistoryLabel('Show history')
      } else {
        setShareLabel('Share')
        setHideHistoryLabel('Hide chat history')
        setShowHistoryLabel('Show chat history')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])


  const OpenMailClient =() => {
    window.open('mailto:akira@hitachienergy.com')  
  }

  const OpenExtLink =() => {
    window.open('https://hitachipowergrids.sharepoint.com/:f:/r/sites/Akira/Shared%20Documents/Materials?csf=1&web=1&e=MmiHUt')  
  }

  const OpenDataSources =() => {
    window.open('https://hitachipowergrids.sharepoint.com/:x:/r/sites/PM_Intelligent_Assistant/Shared%20Documents/Data%20Sources.xlsx?d=wbd7dbf578cbb47b3a60a0a184af18c89&csf=1&web=1&e=dbSSym')  
  }

  return (
    <div>
    <Navbar expand="lg" className={styles.NavBar}>
      <Container className={styles.container}>
        {/* <Navbar.Brand href="#home">  */}
          <Stack horizontal verticalAlign="center" className={styles.stackHeader}>
            <img src={ui?.logo ? ui.logo : Contoso} className={styles.headerIcon} aria-hidden="true" alt="" />
            
          </Stack>
          {/* </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* <Navbar.Collapse id="basic-navbar-nav" className={styles.navbarCollapse}>
          <Nav className="me-auto justify-content-end">

            <Button className={styles.feedbackBtn} onClick={() => setModalShow(true)}>Data Sources</Button>{' '}
            <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
            <Button onClick={OpenMailClient} className={styles.feedbackBtn}>Give Us Feedback</Button>{' '}
            <Button onClick={OpenExtLink} className={styles.feedbackBtn}>Prompting Tips</Button>{' '}
            <Button onClick={handleHistoryClick} className={styles.feedbackBtn}>{appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel}</Button>{' '}
            <Button onClick={handleShareClick} className={styles.feedbackBtn}>Share</Button>{' '} */}


           
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          {/* </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>  
    <Row className={styles.row}>
    <Col className={styles.colsm3}>
    <img src={csLogo} className={styles.for_logo_one} aria-hidden="true" alt="" />   
    </Col>
    <Col className="col-sm-4 text-center">
    {/* <h1 className="project_head">Akira</h1> */}
    </Col>
    <Col className="col-sm-5">
    <nav className={["navbar", "navbar-expand-lg", "navbar-light",styles.navbar].join(' ')}>

  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  {/* <div  id="navbarNav"> */}
    <ul className={styles.navbar_nav}>
      <li className={styles.nav_links}>
        <a className={styles.nav_link} href="#" onClick={() => setModalShow(true)}>Data Sources</a>
        <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      </li>
      <li className={styles.nav_links}>
        <a className={styles.nav_link} href="#" onClick={OpenMailClient}>Give Us Feedback</a>
      </li>
      <li className={styles.nav_links}>
        <a className={styles.nav_link} href="#" onClick={OpenExtLink} >Prompting Tips</a>
      </li>
      <li className={styles.nav_links}>
        <a className={styles.nav_link} href="#" onClick={handleHistoryClick}>{appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel}</a>
      </li>
      <li className={styles.nav_links}>
        <a className={styles.nav_link} href="#" onClick={handleShareClick}>Share</a>
      </li>
    </ul>
  {/* </div> */}
</nav>
    </Col>

</Row>
<Dialog
        onDismiss={handleSharePanelDismiss}
        hidden={!isSharePanelOpen}
        styles={{
          main: [
            {
              selectors: {
                ['@media (min-width: 480px)']: {
                  maxWidth: '600px',
                  background: '#FFFFFF',
                  boxShadow: '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  maxHeight: '200px',
                  minHeight: '100px'
                }
              }
            }
          ]
        }}
        dialogContentProps={{
          title: 'Share the web app',
          showCloseButton: true
        }}>
        <Stack horizontal verticalAlign="center" style={{ gap: '8px' }}>
          <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly />
          <div
            className={styles.copyButtonContainer}
            role="button"
            tabIndex={0}
            aria-label="Copy"
            onClick={handleCopyClick}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? handleCopyClick() : null)}>
            <CopyRegular className={styles.copyButton} />
            <span className={styles.copyButtonText}>{copyText}</span>
          </div>
        </Stack>
      </Dialog>
    </div>
    
  );
}

export default BasicExample;